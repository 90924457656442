import { React, useEffect } from "react";
import "./medicalRegister.scss";
import { url } from "../../api/api";
import { useState } from "react";
import Swal from "sweetalert2";
import { timeNow } from "../../utils/utils";
import { convertNumber } from "../../utils/utils";
import ButtonBack from "../../components/buttonback/ButtonBack";
import Loading from "../../components/Loading";
import { showToast } from "../../utils/utils";
import { Container, Box, TextField, Checkbox, FormControlLabel, Tabs, Tab, Card, CardContent, Typography } from "@mui/material";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import { IoHomeOutline, IoArrowBackSharp } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";


export default function MedicalRegister() {
  const [Area, setArea] = useState([]);
  const [active, setActive] = useState(1);
  const [idArea, setIdArea] = useState("khuvuc1");
  const [listRoom, setListRoom] = useState([]);
  const [areaName, setAreaName] = useState("Khu vực 1");
  const [idBenhNhan, setIdBenhNhan] = useState();
  const [patientinFo, setPatientInfo] = useState({});
  const [activeBoxConfirm, setActiveBoxConfirm] = useState(false);
  const [idRoom, setIdRoom] = useState(0);
  const [render, setRender] = useState(0)
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate()

  useEffect(() => {
    async function fetchListVideo() {
      setLoading(true)
      const requesURL = `${url}/khuvuckhambenh`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();

      setArea(responseJson);
      setLoading(false)

    }
    fetchListVideo();
  }, []);

  //Danh sách phòng khám
  useEffect(() => {
    setLoading(true)
    async function fetchListVideo() {
      const requesURL = `${url}/phongkham/${idArea}`;
      const response = await fetch(requesURL);
      const responseJson = await response.json();
      setListRoom(responseJson);
      setLoading(false)

    }
    fetchListVideo();
  }, [idArea, render]);

  //Thông tin bênh nhân

  const getInfomartion = async () => {
    const response = await fetch(`${url}/benhnhan/${idBenhNhan}`);
    const result = await response.json();
    let data = result[0]
    let obj = {};
    if (data) {
      obj = {
        ID: data.ID,
        Ma: data.Ma,
        Ten: data.Ten,
        CMND: data.CMND,
        DiaChi: data.DiaChi,
        NamSinh: data.NamSinh,
        SDT: data.SoDT,
        NgaySinh: data.NgaySinh,
        GioiTinh: data.GioiTinh,

      };
    }
    console.log(obj)
    setPatientInfo(obj);

  }

  const handleSearch = async ()=> {
    await getInfomartion()
  }



  const handleTabmenu = (id, IdKhuVuc, tenkhucvuc) => {
    setActive(id);
    setIdArea(IdKhuVuc);
    setAreaName(tenkhucvuc);
  };

  const handleChange = (event) => {
    setIdBenhNhan(event.target.value);
  };

  const handleShowboxCofirm = (id) => {
    setIdRoom(id);
    Swal.fire({
      title: "Đăng ký khám bệnh?",
      html: `${listRoom[idRoom] ? listRoom[idRoom].TenPhong : ""} - ${areaName} 
      <br/> STT: ${listRoom[idRoom] ? convertNumber(listRoom[idRoom].STT + 1, 4) : ""}`,
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Lấy số",
      cancelButtonText: "Hủy",
      reverseButtons: true,
      customClass: {
        confirmButton: "btn btn-primary custom-btn",
        cancelButton: "btn btn-secondary custom-btn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleRegisterOnclick();
      }
    });

  };

  const handleRegisterOnclick = () => {
    let obj = {};

    if (idBenhNhan === undefined) {
      showToast("Vui lòng nhập mã bệnh nhân", "error", 3500);

    } else {
      if (Object.keys(patientinFo).length === 0) {
        showToast("Mã bệnh nhân không đúng hoặc chưa đăng ký", "error", 3500);
      } else {
        obj = {
          TenPhong: listRoom[idRoom].TenPhong,
          IdKhuVuc: listRoom[idRoom].IdKhuvuc,
          STT: listRoom[idRoom].STT + 1,
          IdBenhNhan: patientinFo.ID,
          MaBenhNhan: patientinFo.Ma,
          TenBenhNhan: patientinFo.Ten,
          CMND: patientinFo.CMND,
          DiaChi: patientinFo.DiaChi,
          NamSinh: patientinFo.NamSinh,
          IdPhongKham: listRoom[idRoom].IdPhongKham,
          TrangThai: "Đang chờ",
          ThoiGian: timeNow(),
          DichVu_ID: listRoom[idRoom].DichVu_ID,
          GiaTien: listRoom[idRoom].GiaTien
        };

        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(obj),
        };
        setLoading(true);
        fetch(`${url}/dangkykhambenh/addnew`, requestOptions)
          .then((response) => response.json())
          .then((data) => console.log(data));


        //Tăng số TT phòng khám
        const requestOptions1 = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            IdPhongKham: listRoom[idRoom].IdPhongKham,
            STT: listRoom[idRoom].STT + 1,
          }),
        };
        fetch(`${url}/dangkykhambenh/addnewstt`, requestOptions1)
          .then((response) => response.json())
          .then((data) => console.log(data));

        setLoading(false)

        Swal.fire({
          title: "Lấy số thành công",
          text: `STT: ${convertNumber(obj.STT, 4)}`,
          icon: "success",
          confirmButtonText: "Đóng",
          allowOutsideClick: false
        });
        setRender(render + 1)
        setIdRoom()
      }
    }
  };

  return (
    <div className="MedicalRegister mt-0">
      {loading ? <Loading /> : ""}

      <div className="row">
        <div className="col-12">
          <div className="MedicalRegister__top bg-white box_content">
            <div className="">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label >Mã bệnh nhân </label>
                    <input onChange={handleChange} className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Họ và tên </label>
                    <input value={patientinFo.Ten ? patientinFo.Ten : ""}
                      disabled
                      className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Năm sinh </label>
                    <input value={patientinFo.NamSinh ? patientinFo.NamSinh : ""}
                      disabled
                      className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Số BHTY </label>
                    <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Từ ngày </label>
                    <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Đến ngày </label>
                    <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Đối tượng </label>
                    <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
                  </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                  <div className="form-group">
                    <label>Giới tính </label>
                    <input className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" disabled />
                  </div>
                </div>

                <div className="col-4 align-items-end d-flex justify-content-start"
                  onClick={handleSearch}
                  style={{ paddingBottom: '7px' }} >
                  <Button variant="info" className="cl-light btn-radius"  >
                    <FaSearch style={{ marginRight: "5px" }} />
                    Tìm kiếm</Button>
                </div>
              </div>
              {/*               
              <div>
                <label>Ưu tiên:</label>
                <input type="checkbox" placeholder="" id="checkbox-uutien" style={{ flex: "unset" }} />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="col-12">
          <h5 className="text-center mb-1 cl-primary upperCase fs-20 fw-6"  >Khu vực lấy số tiếp nhận</h5>
          <div className=" d-flex justify-content-center mb-4">
            <Tabs textColor="primary" value={tabIndex || 0} onChange={(event, newValue) => setTabIndex(newValue)}>
              {
                Area.map((item, index) => {
                  return (<Tab style={{ fontSize: "16px" }} label={item.TenKhuVuc} onClick={(() => handleTabmenu(item.Id, item.IdKhuVuc, item.TenKhuVuc))} />)
                })
              }
            </Tabs>
          </div>
        </div>

        <div className="MedicalRegister__layout  mt-0 ">
          {listRoom.length > 0
            ? listRoom.map((value, index) => (
              <li
                className="MedicalRegister_room "
                style={{ boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;" }}
                key={value.Id}
                onClick={() => handleShowboxCofirm(index)}
              >
                <p className="fs-20">
                  {value.TenPhong} - {areaName}
                </p>
                <p className="fs-20">
                  {value.MoTa} (STT: {convertNumber(value.STT, 4)})
                </p>
              </li>
            ))
            : "Không có dữ liệu"}
        </div>

        <div className="row mt-5">
          <div className="col-4"></div>
          <div className="col-4"></div>
          <div className="col-4 d-flex justify-content-end">
            <Button variant="warning" className="cl-light btn-radius d-flex align-items-center" onClick={() => navigate("/")}>
              <IoArrowBackSharp className="mr-3" style={{ marginRight: "5px" }} />
              Quay lại
            </Button>

          </div>
        </div>
      </div>

    </div>
  );
}
