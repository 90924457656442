import { useState, useMemo, useEffect } from 'react';
import "./map.scss";
import { Tabs, Tab } from "@mui/material";
import imgLau1 from "../../images/lau-1.jpg";
import imgLau2 from "../../images/lau-2.jpg";
import imgLau3 from "../../images/lau-3.jpg";
import imgLau4 from "../../images/lau-4.jpg";
import imgLau5 from "../../images/lau-5.jpg";
import imgLau6 from "../../images/lau-6.jpg";
import imgLau7 from "../../images/lau-7.jpg";
import imgLau8 from "../../images/lau-8.jpg";
import imgLau9 from "../../images/lau-9.jpg";
import imgLau10 from "../../images/lau-10.jpg";
import imgLau12 from "../../images/lau-12.jpg";
import hamIMg from "../../images/ham-1.jpg";
import tretImg from "../../images/tret.jpg";
import Loading from '../../components/Loading';
import { IoHomeOutline, IoArrowBackSharp } from "react-icons/io5";
import { Button } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


function Map() {
  const [tabIndex, setTabIndex] = useState(0);
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Dữ liệu hình ảnh
  const data = useMemo(() => [
    { id: 0, title: "Tầng hầm", img: hamIMg },
    { id: 1, title: "Lầu 1", img: imgLau1 },
    { id: 2, title: "Lầu 2", img: imgLau2 },
    { id: 3, title: "Lầu 3", img: imgLau3 },
    { id: 4, title: "Lầu 4", img: imgLau4 },
    { id: 5, title: "Lầu 5", img: imgLau5 },
    { id: 6, title: "Lầu 6", img: imgLau6 },
    { id: 7, title: "Lầu 7", img: imgLau7 },
    { id: 8, title: "Lầu 8", img: imgLau8 },
    { id: 9, title: "Lầu 9", img: imgLau9 },
    { id: 10, title: "Lầu 10", img: imgLau10 },
    { id: 12, title: "Lầu 12", img: imgLau12 },
    { id: 13, title: "Trệt", img: tretImg },
  ], []);

  // Preload hình ảnh khi component mount
  useEffect(() => {
    data.forEach((item) => {
      const img = new Image();
      img.src = item.img;
    });
  }, [data]);

  // Xử lý thay đổi tab
  const handleTabChange = (event, newValue) => {
    setLoading(true)
    setTabIndex(newValue);
    setId(newValue);
    setTimeout(() => {
      setLoading(false)

    }, 500)
  };

  return (
    <div className='map-container'>
      <div className="d-flex align-items-center justify-content-between" style={{ paddingBottom: "30px" }}>
        <div className="d-flex align-items-center">
          <p className="m-0 fs-22 pr-5" style={{ borderRight: "1px solid #e2e5ec", paddingRight: "1.5rem" }}>Quản lý thông tin</p>
          <span style={{ paddingLeft: "1.5rem", marginRight: "5px", color: "#898b96" }}><IoHomeOutline /></span>
          <h1 className=" text-left fs-16" style={{ color: "#898b96", marginBottom: '-6px' }}>- Sơ đồ bệnh viện </h1>
        </div>

        <Button variant="warning" className="cl-light btn-radius d-flex align-items-center " onClick={() => navigate("/")}>
          <IoArrowBackSharp className="mr-3" style={{ marginRight: "5px" }} />
          Quay lại
        </Button>
      </div>
      {loading && <Loading />}
      <div className='bg-white box_content p-5'>
        <div className='d-flex justify-content-center '>
          {/* Tabs để chọn tầng */}
          <Tabs
            textColor="primary"
            value={tabIndex}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {data.map((item) => (
              <Tab
                key={item.id}
                style={{ fontSize: "16px" }}
                label={item.title}
              />
            ))}
          </Tabs>

        </div>
        <div className="image-container mt-4">
          <img
            src={data[id].img}
            alt={data[id].title}
            loading="lazy" // Lazy loading để tối ưu hóa tải hình ảnh
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      </div>


    </div>
  );
}

export default Map;