import { useState, useEffect, useMemo } from "react";

import { url } from "../../api/api.js";
import { IoHomeOutline, IoArrowBackSharp } from "react-icons/io5";
import Loading from "../../components/Loading";
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../utils/utils";
import moment from "moment/moment.js";
import { Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";


export default function SearchBill() {
  const [idBenhNhan, setIdBenhNhan] = useState("");
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5, // Thiết lập pageSize mặc định
  });
  const navigate = useNavigate()

  const handleInput = (event) => {
    setIdBenhNhan(event.target.value);
  };

  const handleSearch = async () => {
    if (!idBenhNhan) {
      showToast("Vui lòng nhập mã bệnh nhân", "error", 3500);
      return null;
    }

    const data = await getInfomartion();

    if (!data) {
      showToast("Không tìm thấy thông tin bệnh nhân", "error", 3500);
      setData({});
      setRows([]);
      setLoading(false);
      return null;
    }

    const rows = [{
      id: data.BenhNhan_ID,
      fullName: data.Ten,
      birthday: data.NamSinh,
      billNumber: data.SoHoaDon,
      createdDate: data.NgayLap,
      address: data.DiaChi,
      note: data.GhiChu,
      totalPremium: (data.TongGTThanhToan ? data.TongGTThanhToan.toLocaleString("vi-VN") : "0") + " đồng",
    }]

    setRows(rows);
  }


  const getInfomartion = async () => {
    setLoading(true);
    const response = await fetch(`${url}/bill/${idBenhNhan}`);
    const result = await response.json();
    setLoading(false);
    return result[0];
  }



  const columns = useMemo(() => [
    {
      field: 'id', headerName: 'Mã bệnh nhân', width: 120, headerAlign: "center", align: "center",
      valueGetter: (row, index, test) => {

        return index.id
      }
    },
    { field: 'fullName', flex: 1, headerName: 'Tên', headerAlign: "center", align: "center" },
    { field: 'birthday', flex: 1, headerName: 'Năm sinh', headerAlign: "center", align: "center" },
    { field: 'billNumber', flex: 1, headerName: 'Số hóa đơn', headerAlign: "center", align: "center" },
    {
      field: 'createdDate',
      headerName: 'Ngày lập',
      sortable: false,
      flex: 1,
      headerAlign: "center", align: "center",
      valueGetter: (row) => {
        return row ? moment(row).format("DD/MM/YYYY HH:mm") : '';
      }
    },
    { field: 'address', flex: 1, headerName: 'Địa chỉ', headerAlign: "center", align: "center" },
    { field: 'note', flex: 1, headerName: 'Ghi chú', headerAlign: "center", align: "center" },
    { field: 'totalPremium', flex: 1, headerName: 'Tổng thanh toán', headerAlign: "center", align: "center" },


  ], []);

  return (
    <div className="searchMedia">
      {loading && <Loading />}
      <div className="d-flex align-items-center justify-content-between" style={{ paddingBottom: "30px" }}>
        <div className="d-flex align-items-center">
          <p className="m-0 fs-22 pr-5" style={{ borderRight: "1px solid #e2e5ec", paddingRight: "1.5rem" }}>Quản lý thông tin</p>
          <span style={{ paddingLeft: "1.5rem", marginRight: "5px", color: "#898b96" }}><IoHomeOutline /></span>
          <h1 className=" text-left fs-14 mb-0" style={{ color: "#898b96" }}>- Tra cứu hóa đơn</h1>
        </div>

        <Button variant="warning" className="cl-light btn-radius d-flex align-items-center" onClick={() => navigate("/")}>
          <IoArrowBackSharp className="mr-3" style={{ marginRight: "5px" }} />
          Quay lại
        </Button>
      </div>

      <div className="bg-white box_content home_container">
        <div className="row mb-4 m-form__group">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="">Mã bệnh nhân </label>
              <input onChange={handleInput} className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
            </div>
          </div>
          <div className="col-4 align-items-end d-flex  pb-3" >
            <Button variant="info" className="cl-light btn-radius" onClick={handleSearch}>
              <FaSearch style={{ marginRight: "5px" }} />
              Tìm kiếm</Button>
          </div>
        </div>

        <Paper sx={{ height: 350, width: '100%', overflow: 'visible' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            disableColumnMenu
            hideSortIcons={false}
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaders': {
                position: 'relative',
                zIndex: 2, /* Đảm bảo header có z-index cao hơn thanh cuộn */
              },
              '& .MuiDataGrid-virtualScroller': {
                zIndex: 1,
              },
            }}
            localeText={{
              footerPaginationRowsPerPage: "Số hàng mỗi trang:",
              noRowsLabel: "Không có dữ liệu",
              footerRowSelected: (count) => ``,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            loading={loading}
          />
        </Paper>
      </div>

    </div >
  );
}
