import 'react-bootstrap-buttons/dist/react-bootstrap-buttons.css';
import "./__base.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScreenTNDV from "./pages/screen/Screen__TNDV";
import ReceivePage from "./pages/receivePage/ReceivePage";
import "./app.scss";
import Today from "./components/date/Today";
import imgLogo from "./images/logo.jpg"
import GetNumberClinical from "./pages/GetNumberClinical/GetNumberClinical";
import GetnumberReceive from "./pages/getnumberreceive/GetnumberReceive";
import HomePage from "./pages/homePage/HomePage";
import TraCuuBenhAn from "./pages/searchBenhAn/TraCuuBenhAn";
import SearchService from "./pages/searchService/SearchService"
import SearchBill from "./pages/searchBill/SearchBill";
import Rating from "./pages/rating/Rating";
import Map from "./pages/Map/Map";
import ScreenPM from "./pages/screen_PM/ScreenPM";
import ScreenCC from "./pages/screen_CC/ScreenCC";
import ScreenHSTC from "./pages/screen_HSTC/ScreenHSTC";
import MedicalRegister from "./pages/medicalRegister/MedicalRegister";
import Pay from "./pages/pay/Pay";
import ScreenBS from "./pages/screen_BS/ScreenBS";
import ScreenThuPhi from "./pages/screen_ThuPhi/ScreenThuPhi";
import ScreenPhongKham from "./pages/Screen_PhongKham/ScreenPhongKham";
import ScreenPhongSanh from "./pages/Screen_PhongSanh/ScreenPhongSanh";
import ListScreen from "./pages/ListScreen/ListScreen";
import Screen__TNUT from "./pages/screen copy/Screen__TNUT";
import Screen__TNDH from "./pages/screen __TNDH/Screen__TNDH";
import ScreenQThuoc from "./screen_QThuoc/ScreenQThuoc";
import ScreenChuanDoanHinhAnh from "./screen_ChuanDoanHinhAnh/ScreenChuanDoanHinhAnh";
import Login from "./pages/login/Login";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useState} from 'react'
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Test from "./pages/Test";

function App() {

  const [tokenLogin,setTokenLogin] = useState()
  let token;
  
  tokenLogin ? localStorage.setItem("token",tokenLogin) : console.log(123);

  token = localStorage.getItem("token")
  if(token){
    return <Login token={setTokenLogin}/>
  }
  // if(!token){
  //   return <Login token={setTokenLogin}/>
  // }

 
  else{
     return (
    <BrowserRouter>
      <div className="App">
        <ToastContainer theme="dark"/>
        <div className="App__header home_container">
          <a href="/"><img src={imgLogo} alt="logo" /></a>
          <div className="">
            <Today/>
          </div>
        </div>
        <div className="App__container home_container">
          <Routes>
            <Route  exact path="/" element={<HomePage/>} />
            <Route  exact path="/test" element={<Test/>} />
            <Route  exact path="/login" element={<Login token={setTokenLogin}/>} />
            <Route  path="/tiepnhan" element={<ReceivePage />} />
            <Route path="/layso-tiepnhan"  element={<GetnumberReceive />} />
            <Route path="/dangkykhambenh"  element={<MedicalRegister />} />
            <Route path="/tracuubenhan"  element={<TraCuuBenhAn />} />
            <Route path="/tracuudichvu"  element={<SearchService />} />
            <Route path="/tracuuhoadon"  element={<SearchBill />} />
            <Route path="/danhgia"  element={<Rating />} />
            <Route path="/bando"  element={<Map />} />
            <Route path="/thanhtoan"  element={<Pay />} />\
            <Route path="/manhinh/bac-si"  element={<ScreenBS />} />

            {/* màn hình */}
            <Route path="/manhinh/dich-vu"  element={<ScreenTNDV />} />
            <Route path="/manhinh/dat-hen"  element={<Screen__TNDH />} />
            <Route path="/manhinh/uu-tien"  element={<Screen__TNUT />} />
            <Route path="/manhinh/hoi-suc-tich-cuc"  element={<ScreenHSTC />} />
            <Route path="/manhinh/cap-cuu"  element={<ScreenCC />} />
            <Route path="/manhinh/thu-phi"  element={<ScreenThuPhi />} />
            <Route path="/manhinh/phong-mo"  element={<ScreenPM />} />
            <Route path="/manhinh/phong-sanh"  element={<ScreenPhongSanh />} />
            <Route path="/manhinh/lay-thuoc"  element={<ScreenQThuoc />} />
            <Route path="/manhinh/chuan-doan"  element={<ScreenChuanDoanHinhAnh />} />


            <Route path="/manhinh"  element={<ListScreen />} />
            <Route path="/manhinh/phongkham"  element={<ScreenPhongKham />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
  }
  
 
}


export default App;
