import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  Container,
  Box,
  Paper,
  Avatar,
  IconButton,
  useTheme,
} from "@mui/material";
import { Button as BootstrapButton } from "react-bootstrap";
import Swal from "sweetalert2";
import { convertNumber, timeNow } from "../../utils/utils";
import { url } from "../../api/api";
import Loading from "../../components/Loading";
import img2 from "../../images/OrWKYWw4.jpg";
import { ArrowForward, AssignmentInd } from "@mui/icons-material";
import { IoHomeOutline, IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";


export default function GetnumberReceive() {
  const [id, setId] = useState();
  const [service, setService] = useState([]);
  const [objNumber, setObjNumber] = useState({});
  const [render, setRender] = useState(0);
  const [active, setActive] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const requesURL = `${url}/dichvutiepnhan`;
        const response = await fetch(requesURL);
        const responseJson = await response.json();
        setService(responseJson);
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };

    fetchData();
  }, [render]);

  const handleActiveService = (ID, index) => {
    setId(index);
    setActive(ID);
    let obj = {
      STT: service[index].STT + 1,
      TenDichVu_TN: service[index].TenDichVu_TN,
      IdDichVu_TN: service[index].IdDichVu_TN,
      slug: service[index].slug,
      TrangThai: "Đang chờ",
      ThoiGian: timeNow(),
    };
    setObjNumber(obj);
  };

  const getNumberOnclick = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    if (id !== undefined) {
      setLoading(true);
      fetch(`${url}/dichvutiepnhan`, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => console.log(responseJson));

      fetch(`${url}/dichvutiepnhanstt`, requestOptions)
        .then((response) => response.json())
        .then((responseJson) => console.log(responseJson));
      setLoading(false);
      Swal.fire({
        title: "Lấy số thành công",
        text: `Khám ${data.TenDichVu_TN} : ${data.STT}`,
        icon: "success",
        confirmButtonText: "Đóng",
        allowOutsideClick: false,
      });
      setRender(render + 1);
      setId();
      setActive();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Vui lòng chọn dịch vụ tiếp nhận",
        allowOutsideClick: false,
      });
    }
  };

  return (
    <div>
      <Container maxWidth="lg" className="w-100" style={{ maxWidth: "unset" }}>
        {loading && <Loading />}
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: "bold", color: "primary.main" }}>
            KHU VỰC LẤY SỐ TIẾP NHẬN
          </Typography>

          <Paper elevation={3} sx={{ borderRadius: 4, overflow: "hidden" }}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Box
                    component="img"
                    src={img2}
                    alt="ảnh 1"
                    sx={{ width: "100%", borderRadius: 2, boxShadow: 3 }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <List>
                    {service.map((value, index) => (
                      <ListItem
                        button
                        key={value.ID}
                        onClick={() => handleActiveService(value.ID, index)}
                        sx={{
                          bgcolor:
                            active === value.ID ? "primary.light" : "background.paper",
                          mb: 1,
                          borderRadius: 2,
                          transition: "all 0.3s ease",
                          "&:hover": {
                            bgcolor: "primary.light",
                            transform: "translateX(5px)",
                          },
                        }}
                      >
                        <Avatar sx={{ bgcolor: "primary.main", mr: 2 }}>
                          <AssignmentInd />
                        </Avatar>
                        <ListItemText
                          primary={value.TenDichVu_TN}
                          primaryTypographyProps={{ fontWeight: "medium" }}
                        />
                        <IconButton>
                          <ArrowForward />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                  <Box sx={{ mt: 2, textAlign: "center" }}>
                    <Typography variant="h3" gutterBottom sx={{ fontWeight: "bold", color: "primary.main" }}>
                      {typeof id === "number" ? convertNumber(service[id].STT, 4) : 0}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => getNumberOnclick(objNumber)}
                      sx={{ width: "100%", py: 2, borderRadius: 2, fontSize: "1.1rem" }}
                      startIcon={<AssignmentInd />}
                    >
                      Lấy số
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Paper>
        </Box>
      </Container>

      <Typography className="d-flex justify-content-end" style={{ padding: "0 24px" }} variant="h4" align="center" gutterBottom sx={{ fontWeight: "bold", color: "primary.main" }}>
        <BootstrapButton variant="warning" className="cl-light btn-radius d-flex align-items-center " onClick={() => navigate("/")}>
          <IoArrowBackSharp className="mr-3" style={{ marginRight: "5px" }} />
          Quay lại
        </BootstrapButton >
      </Typography>


    </div>
  );
}