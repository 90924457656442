import React from "react";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
// import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Test = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Typography variant="h3" color="primary" gutterBottom sx={{ fontWeight: "bold" }}>
        KHU VỰC LẤY SỐ TIẾP NHẬN
      </Typography>
      <Grid container spacing={3} justifyContent="center" sx={{ maxWidth: "80%" }}>
        {["Khám đặt hẹn", "Khám dịch vụ", "Khám ưu tiên"].map((text, index) => (
          <Grid item key={index} xs={12} sm={4} md={3}>
            <Card
              sx={{
                p: 3,
                textAlign: "center",
                boxShadow: 5,
                transition: "0.3s",
                borderRadius: 3,
                bgcolor: "white",
                ":hover": { transform: "scale(1.05)", bgcolor: "#e3f2fd" },
              }}
            >
              <CardContent>
                <Typography variant="h5" fontWeight="bold">
                  {text}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ mt: 4, textAlign: "center" }}>
        <Typography variant="h4" color="success.main" fontWeight="bold">
          0
        </Typography>
        <Button variant="contained" color="primary" size="large" sx={{ mt: 2, width: "200px", height: "70px", fontSize: "1.5rem" }}>
          Lấy số
        </Button>
      </Box>
      {/* <Box sx={{ position: "absolute", bottom: 30, right: 30 }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AccessTimeIcon />}
          sx={{ width: "180px", height: "60px", fontSize: "1.2rem" }}
        >
          Quay lại
        </Button>
      </Box> */}
    </Box>
  );
};

export default Test;
