import { useState, useEffect, useMemo } from "react";
import { convertNumber } from "../../utils/utils";
import "./searchservice.scss";
import Loading from "../../components/Loading";
import { url } from "../../api/api.js";
import { IoHomeOutline, IoArrowBackSharp } from "react-icons/io5";
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../utils/utils";
import moment from "moment/moment.js";
import { Button } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { TfiReload } from "react-icons/tfi";

export default function SearchService() {
  const [idDichvu, setIdDichVu] = useState('');
  const [listSearch, setListSearch] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5, // Thiết lập pageSize mặc định
  });
  const navigate = useNavigate();

  useEffect(() => {
    getInfomartion();
  }, []);

  const getInfomartion = async (id) => {
    setLoading(true);
    let param;
    if (id) {
      param = `service=${id}`;
    }
    try {
      const response = await fetch(`${url}/service/?${param}`);
      const result = await response.json();
      let rows = [];

      result.forEach((item) => {
        let row = {
          id: item.ID,
          serviceCode: item.Ma,
          serviceName: item.Ten,
          createdDate: item.Createdate,
          price: item.DonGia.toLocaleString("vi-VN") + " đồng",
          priceBHYT: item.DonGiaBHYT.toLocaleString("vi-VN") + " đồng",
        };
        rows.push(row);
      });
      setRows(rows);
    } catch (e) {
      console.error(e);
      setRows([]);
    }
    setLoading(false);
  };

  const handleInput = (event) => {
    setIdDichVu(event.target.value);
  };

  const handleSearch = async () => {
    if (!idDichvu) {
      showToast("Vui lòng nhập tên hoặc mã dịch vụ", "error", 3500);
      return null;
    }

    const data = await getInfomartion(idDichvu);

    if (!data.length) {
      showToast("Không tìm thấy thông tin dịch vụ", "error", 3500);
      setRows([]);
      return null;
    }

    let rows = [];
    data.forEach((item) => {
      let row = {
        id: item.ID,
        serviceCode: item.Ma,
        serviceName: item.Ten,
        price: item.DonGia.toLocaleString("vi-VN") + " đồng",
        priceBHYT: item.DonGiaBHYT.toLocaleString("vi-VN") + " đồng",
      };
      rows.push(row);
    });

    setRows(rows);
  };

  const reset = () => {
    setIdDichVu("");
    getInfomartion();
  };

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'Id',
        width: 100,
        headerAlign: "center",
        align: "center",
        valueGetter: (params) => {
          return params;
        },
      },
      { field: 'serviceCode', width: 150, headerName: 'Mã dịch vụ', headerAlign: "center", align: "center" },
      { field: 'serviceName', flex: 2, headerName: 'Tên dịch vụ', headerAlign: "center", align: "left" },
      {
        field: 'createdDate',
        flex: 2,
        headerName: 'Ngày tạo',
        headerAlign: "center",
        align: "center",
        width: 150,
        valueGetter: (params) => {
          return params ? moment(params).format("DD/MM/yyyy") : "";
        },
      },
      { field: 'price', width: 150, headerName: 'Giá', headerAlign: "center", align: "center" },
      { field: 'priceBHYT', width: 150, headerName: 'Giá BHYT', headerAlign: "center", align: "center" },
    ],
    []
  );

  return (
    <div className="searchMedia">
      {loading ? <Loading /> : ""}

      <div className="d-flex align-items-center justify-content-between" style={{ paddingBottom: "30px" }}>
        <div className="d-flex align-items-center">
          <p className="m-0 fs-22 pr-5" style={{ borderRight: "1px solid #e2e5ec", paddingRight: "1.5rem" }}>Quản lý thông tin</p>
          <span style={{ paddingLeft: "1.5rem", marginRight: "5px", color: "#898b96" }}><IoHomeOutline /></span>
          <h1 className=" text-left fs-14 " style={{ color: "#898b96", marginBottom: '-6px' }}>- Tra cứu dịch vụ</h1>
        </div>

        <Button variant="warning" className="cl-light btn-radius d-flex align-items-center btn-radius" onClick={() => navigate("/")}>
          <IoArrowBackSharp className="mr-3" style={{ marginRight: "5px" }} />
          Quay lại
        </Button>
      </div>

      <div className="bg-white box_content home_container pb-3">
        <div className="row mb-2 m-form__group">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className=""> Tên dịch vụ | mã dịch vụ </label>
              <input onChange={handleInput} value={idDichvu} className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
            </div>
          </div>
          <div className="col-4 align-items-end d-flex  pb-3">
            <Button variant="info" className="cl-light btn-radius mr-3" onClick={handleSearch}>
              <FaSearch style={{ marginRight: "5px" }} />
              Tìm kiếm
            </Button>

            <Button variant="warning" className="cl-light btn-radius d-flex align-items-center btn-radius" onClick={reset}>
              <TfiReload className="mr-3" style={{ marginRight: "5px" }} />
              Đặt lại
            </Button>
          </div>
        </div>
        <Paper sx={{ height: 350, width: '100%', overflow: 'visible' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            disableColumnMenu
            hideSortIcons={false}
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaders': {
                position: 'relative',
                zIndex: 2, /* Đảm bảo header có z-index cao hơn thanh cuộn */
              },
              '& .MuiDataGrid-virtualScroller': {
                zIndex: 1,
              },
            }}
            localeText={{
              footerPaginationRowsPerPage: "Số hàng mỗi trang:", 
              noRowsLabel: "Không có dữ liệu",
              footerRowSelected: (count) => ``,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            loading={loading}
          />
        </Paper>
      </div>
    </div>
  );
}