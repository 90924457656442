import React, { useEffect, useState } from "react";
import moment from "moment";

export default function Today() {
  const [dateTime, setDateTime] = useState(moment().format("HH:mm:ss, MM/DD/YYYY"));

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(moment().format("HH:mm:ss, MM/DD/YYYY"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <div className="date fs-20" style={{fontSize:"26px !important"}}>{dateTime}</div>
    </div>
  );
}
