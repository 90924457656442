import React from 'react'
import { Hypnosis } from 'react-cssfx-loading';
import { Circles } from "react-loader-spinner";

function Loading() {

  <Hypnosis color="rgb(77,183,72)" width="100px" height="100px" duration='3s'/>
  return (
    <div className='loading'>
      <Hypnosis />
    </div>
  )
}

export default Loading