import { useState, useEffect, useMemo } from "react";
import "./TraCuuBenhAn.scss";
import { url } from "../../api/api";
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Pagination from "@mui/material/Pagination";
import Paper from '@mui/material/Paper';
import { IoHomeOutline, IoArrowBackSharp } from "react-icons/io5";
import Button from 'react-bootstrap/Button';
import moment from "moment/moment";
import { showToast } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";

function TraCuuBenhAn() {
  const [idBenhNhan, setIdBenhNhan] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [rows, setRows] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5, // Thiết lập pageSize mặc định
  });
  const navigate = useNavigate()

  const handleChange = (event) => {
    setIdBenhNhan(event.target.value);
  };

  const handleSearch = async () => {
    if (!idBenhNhan) {
      showToast("Vui lòng nhập mã bệnh nhân", "info", 3500);
      return null;
    }
    const benhnhan = await getInfomartion();
    const benhan = await getInfomartion1();
    if (!benhnhan.length) {
      showToast("Không có tìm thấy thông tin bệnh nhân", "info", 3500);
      setRows([]);
      setData({})
      return null;
    }
    const data = { ...benhnhan[0], ...benhan[0] }
    setData(data);
    setRows([{ id: data.ID || "-", name: data.Ten || "-", birthday: data.NgaySinh || "-", diagnose: data.ChanDoan || "-", examinationTime: data.NgayKham },])
  }

  const getInfomartion = async () => {
    const response = await fetch(`${url}/benhnhan/${idBenhNhan}`);
    const result = await response.json();
    return result;
  }

  const getInfomartion1 = async () => {
    const response = await fetch(`${url}/patient/${idBenhNhan}`);
    const result = await response.json();
    return result;

  }

  // Tạo cột cố định bằng useMemo để tối ưu hiệu suất
  const columns = useMemo(() => [
    { field: 'id', headerName: 'STT', width: 100, headerAlign: "center", align: "center" },
    { field: 'name', flex: 1, headerName: 'Tên bệnh nhân', headerAlign: "center", align: "center" },
    {
      field: 'birthday', flex: 1, headerName: 'Ngày sinh', headerAlign: "center", align: "center", valueGetter: (row) => {
        return row ? moment(row).format("DD/MM/YYYY") : '';
      }
    },
    { field: 'diagnose', flex: 1, headerName: 'Chẩn đoán', headerAlign: "center", align: "center" },
    // { field: 'diagnose', headerName: 'Chẩn đoán', type: 'number', flex: 1, headerAlign: "center", align: "right" },
    {
      field: 'examinationTime',
      headerName: 'Thời gian khám',
      sortable: false,
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (row) => {
        return row ? moment(row).format("DD/MM/YYYY HH:mm") : '-';
      }
    }
  ], []);

  // Fake data bệnh án (có thể thay bằng API)

  return (
    <div className="searchMedia " >
      <div className="d-flex align-items-center justify-content-between" style={{ paddingBottom: "30px" }}>
        <div className="d-flex align-items-center">
          <p className="m-0 fs-22 pr-5" style={{ borderRight: "1px solid #e2e5ec", paddingRight: "1.5rem" }}>Quản lý thông tin</p>
          <span style={{ paddingLeft: "1.5rem", marginRight: "5px", color: "#898b96" }}><IoHomeOutline /></span>
          <h1 className=" text-left fs-14" style={{ color: "#898b96", marginBottom: '-6px' }}>- Thông tin bệnh án </h1>
        </div>

        <Button variant="warning" className="cl-light btn-radius d-flex align-items-center " onClick={() => navigate("/")}>
          <IoArrowBackSharp className="mr-3" style={{ marginRight: "5px" }} />
          Quay lại
        </Button>
      </div>

      {/* Ô tìm kiếm */}
      <div className="customTable bg-white box_content " style={{ paddingTop: "30px" }}>
        {/* <div className="searchMedia-input" style={{ display: "flex", alignItems: "center" }}>
          <p className="m-0">Tìm kiếm</p>
          <input
            className="ml-4"
            style={{ marginLeft: "15px" }}
            onChange={handleChange}
            type="number"
            placeholder="Nhập tên bệnh nhân | mã bệnh nhân"
          />
        </div> */}

        <div className="row mb-4 m-form__group">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label className="">Mã bệnh nhân </label>
              <input onChange={handleChange} type="number" className="form-control ng-pristine ng-valid ng-empty ng-touched" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 align-items-end d-flex pb-3" >
            <Button variant="info" className="cl-light btn-radius" onClick={handleSearch}><FaSearch style={{ marginRight: "5px" }} />Tìm kiếm</Button>
          </div>
        </div>

        {/* Bảng hiển thị bệnh án */}
        <Paper sx={{ height: 350, width: '100%', overflow: 'visible' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            disableColumnMenu
            hideSortIcons={false}
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaders': {
                position: 'relative',
                zIndex: 2, /* Đảm bảo header có z-index cao hơn thanh cuộn */
              },
              '& .MuiDataGrid-virtualScroller': {
                zIndex: 1,
              },
            }}
            localeText={{
              footerPaginationRowsPerPage: "Số hàng mỗi trang:",
              noRowsLabel: "Không có dữ liệu",
              footerRowSelected: (count) => ``,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            loading={loading}
          />
        </Paper>
      </div>

    </div>

  );
}

export default TraCuuBenhAn;
