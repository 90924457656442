import { useState, useEffect, useMemo } from "react";

import "./pay.scss";
import { url } from "../../api/api";
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import Pagination from "@mui/material/Pagination";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../utils/utils";
import { Button } from "react-bootstrap";
import Loading from "../../components/Loading";
import { IoHomeOutline, IoArrowBackSharp } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";


export default function Pay() {
  const [idBenhNhan, setIdBenhNhan] = useState(0);
  const [listData, setListData] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5, // Thiết lập pageSize mặc định
  });
  const navigate = useNavigate();


  const handleInput = (event) => {
    setIdBenhNhan(event.target.value);
  };

  const getInfomartion = async () => {
    const response = await fetch(`${url}/benhnhan/${idBenhNhan ? idBenhNhan : 1}`);
    const result = await response.json();
    return result[0];
  }

  const getPaymentInfortion = async () => {
    try {
      const response = await fetch(`${url}/pay/${idBenhNhan}`);
      const result = await response.json();
      console.log(result[0])
      return result[0];
    } catch (e) {
      console.error(e)
    }
  }

  const handleSearch = async () => {
    if (!idBenhNhan) {
      showToast("Vui lòng nhập mã bệnh nhân", "error", 3500);
      return null;
    }
    setLoading(true)
    let userInfomation = await getInfomartion();
    // let payInfomation = await getPaymentInfortion();
    if (!userInfomation) {
      showToast("Không tìm thấy thông tin bệnh nhân", "error", 3500);
      setData({});
      setRows([]);
      setLoading(false);
      return null;
    }

    setLoading(false)
    const data = { ...userInfomation }
    // const data = { ...userInfomation, ...payInfomation }
    setData(data);

    console.log(data);
    const rows = [
      {
        id: data.BenhNhanID || "",
        content: data.NoiDung || "",
        examinationTime: data.NgayKham || "",
        number: data.SoLuong || "",
        unitPrice: data.DonGia || "",
        paymentValue: data.GiaTriThanhToan || "",
        valuePaid: data.GiaTriDaThanhToan || "",
        notedBHTN: "",
        unitPriceInsurance: data.DonGiaBHYT || "",
        introMoneyBHYT: data.ThanhTienBHYT || "",
        BHYTPay: data.BHYTChiTra || "",
      }
    ]
    // setRows(rows);
    setRows([]);

  }


  let total = 0;
  for (let i = 0; i < listData.length; i++) {
    total = total + listData[i].ThanhTien;
  }


  const columns = useMemo(() => [
    { field: 'id', headerName: 'STT', width: 100, headerAlign: "center" },
    { field: 'content', flex: 1, headerName: 'Nội dung', headerAlign: "center" },
    {
      field: 'examinationTime',
      headerName: 'Thời gian khám',
      sortable: false,
      flex: 1,
      headerAlign: "center",
      valueGetter: (row) => {
        return row ? moment(row).format("DD/MM/YYYY HH:mm") : '';
      }
    },
    { field: 'number', flex: 1, headerName: 'Số lượng', headerAlign: "center" },
    { field: 'unitPrice', flex: 1, headerName: 'Đơn giá', headerAlign: "center" },
    { field: 'paymentValue', flex: 1, headerName: 'Giá trị thanh toán', headerAlign: "center" },
    { field: 'valuePaid', flex: 1, headerName: 'Giá trị đã thanh toán', headerAlign: "center" },
    { field: 'notedBHTN', flex: 1, headerName: 'BHTN ghi nhận', headerAlign: "center" },
    { field: 'unitPriceInsurance', flex: 1, headerName: 'Đơn giá bảo hiểm', headerAlign: "center" },
    { field: 'introMoneyBHYT', flex: 1, headerName: 'Thành tiền BHYT', headerAlign: "center" },
    { field: 'BHYTPay', flex: 1, headerName: 'BHYT chi trả', headerAlign: "center" },
    // { field: 'diagnose', headerName: 'Chẩn đoán', type: 'number', flex: 1, headerAlign: "center", align: "right" },

  ], []);

  return (
    <div className=" ">
      {loading && <Loading />}
      <div className="d-flex align-items-center justify-content-between" style={{ paddingBottom: "30px" }}>
        <div className="d-flex align-items-center">
          <p className="m-0 fs-22 pr-5" style={{ borderRight: "1px solid #e2e5ec", paddingRight: "1.5rem" }}>Quản lý thông tin</p>
          <span style={{ paddingLeft: "1.5rem", marginRight: "5px", color: "#898b96" }}><IoHomeOutline /></span>
          <h1 className=" text-left fs-14 " style={{ color: "#898b96",marginBottom:'-6px ' }}>- Thông tin thanh toán </h1>
        </div>

        <Button variant="warning" className="cl-light btn-radius d-flex align-items-center" onClick={() => navigate("/")}>
          <IoArrowBackSharp className="mr-3" style={{ marginRight: "5px" }} />
          Quay lại
        </Button>
      </div>

      <div className="bg-white box_content home_container">
        <div className="row mb-4 m-form__group">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label >Mã bệnh nhân </label>
              <input onChange={handleInput} className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label >Họ và tên</label>
              <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" value={data.Ten} />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label >Tuổi</label>
              <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6"
                value={data.NamSinh ? moment().diff(moment(data.NamSinh, "YYYY"), "years") : ""} />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label >Số điện thoại</label>
              <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" value={data.SoDT} />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label >Địa chỉ</label>
              <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary cl-primary1 fw-6" value={data.DiaChi} />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
            <div className="form-group">
              <label >Thanh toán</label>
              <input disabled className="form-control ng-pristine ng-valid ng-empty ng-touched cl-primary1 fw-6" value={(data.total)} />
            </div>
          </div>
          <div className="col-12 align-items-end d-flex justify-content-end" >
            <Button variant="info" className="cl-light btn-radius" onClick={handleSearch}>
            <FaSearch style={{ marginRight: "5px" }} />Tìm kiếm</Button>
          </div>
        </div>

        <Paper sx={{ height: 300, width: '100%', overflow: 'visible' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[5, 10, 20]}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            disableColumnMenu
            hideSortIcons={false}
            sx={{
              border: 0,
              '& .MuiDataGrid-columnHeaders': {
                position: 'relative',
                zIndex: 2, /* Đảm bảo header có z-index cao hơn thanh cuộn */
              },
              '& .MuiDataGrid-virtualScroller': {
                zIndex: 1,
              },
            }}
            localeText={{
              footerPaginationRowsPerPage: "Số hàng mỗi trang:",
              noRowsLabel: "Không có dữ liệu",
              footerRowSelected: (count) => ``,
            }}
            components={{
              Toolbar: GridToolbar,
            }}
            loading={loading}
          />
        </Paper>

      </div>



    </div>
  );
}
